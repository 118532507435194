// @note: recurly doesn't support webpack and package managers,
// loading it like this at least adds it to the window

import "Vendor/recurly.js";
if (window.recurlyKey && window.recurly) {
  window.recurly.configure(window.recurlyKey);  
}

import axios from "axios";

export const tokenFunctions = {
  /**
   * @method getBillingToken
   * @param {Object} params to send to recurly with cc info
   * @param {Function} resolveFn the fn to call when response received and resolved
   * @description fetches a billing token from recurly
   */
  getBillingToken(params) {
    return new Promise((resolve, reject) => {
      window.recurly.token(params, function(error, token) {
        if (error) {
          reject(error);
        } else {
          resolve(token.id);
        }
      });
    });
  }
};
