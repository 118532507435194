import axios from "axios";
import { tokenFunctions } from "./billingServices.js";

const accountProfileServices = {
  /**
   * @method updateBasicInfo
   * @param {Number, String} userId
   * @param {Object} formData
   * @return {Promise}
   * @description updates user account info like first name, email, phone
   */
  updateBasicInfo: (userId, formData) => {
    return axios.put(`/api/v2/users/${userId}.json`,
      { user: formData }
    );
  },

  /**
   * @method updateNotifPreferences
   * @param {Number, String} userId
   * @param {Object} prefsData { preferenceName: setting, ...}
   */
  updateNotifPreferences: (userId, prefsData) => {
    const notifTypes = Object.keys(prefsData);
    const notifPreferences = notifTypes.reduce(function(prefsObj, notifType) {
      return {
        notification_type: notifType,
        enabled: prefsData[notifType]
      };
    }, {});
    return axios.put(`/api/v2/users/${userId}/notification_preferences/create_or_update`, {
      user_id: userId,
      ...notifPreferences
    });
  },

  /**
   * @method updatePassword
   * @param {Number, String} userId
   * @param {Object} formData
   * @return {Promise}
   */
  updatePassword(userId, formData) {
    return axios.put(`/api/v2/users/${userId}/update_password.json`,
      { user: formData }
    );
  },

  /**
   * @method sendPasswordResetEmail
   * @param {String} email
   * @return {Promise}
   */
  sendPasswordResetEmail(email) {
    return axios.post("/api/v2/users/reset_password.json", {
      email
    });
  },

  /**
   * @method getCreditCardToken
   * @param {Number, String} userId
   * @param {Object} billingParams
   * @return {Promise}
   * @description when user submits billing info,
   * gets a billing token for billing info then updates that token
   */
  getCreditCardToken(userId, billingParams) {
    return tokenFunctions.getBillingToken(billingParams);
  },

  /**
   * @method updateBillingInfo
   * @param {Number, String} userId
   * @param {String} tokenId
   * @param {String} paypalBillingAgreementId
   * @return {Promise}
   * @description updates user billing info with a token_id for credit
   * cards, or a paypal_billing_agreement_id for paypal
   */

  updateBillingInfo(userId, tokenId, paypalBillingAgreementId) {
    return axios.post("/api/v2/billing_infos.json", {
      billing_info: {
        user_id: userId,
        token_id: tokenId,
        paypal_billing_agreement_id: paypalBillingAgreementId
      }
    });
  },

  /**
   * @method verifyBillingInfo
   * @param {Number, String} userId
   * @return {Promise}
   * @description verifiies user current billing info
   */

  verifyBillingInfo(userId) {
    return axios.patch("/api/v2/billing_infos/verify.json", {
      billing_info: {
        user_id: userId
      }
    });
  },

  /**
   * @method updateEmailPreferences
   * @param {String} userId
   * @param {String} email
   * @param {String} preference - can be "none", "basic", or "all"
   * @return {Promise}
   * @description updates user email preferences for marketing and shipping emails
   */

  updateEmailPreferences(userId, email, preference) {
    return axios.post("/account/profile/${userId}/email_preference.json", {
      email,
      preference
    });
  },

  /**
   * @method logOut
   * @param none
   * @return {Promise}
   * @description For React Native App only. Logout a user.
   */
  logOut: () => {
    return axios.get("/users/sign_out.json");
  }
};

export default accountProfileServices;
